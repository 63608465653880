@import "mixins";
@import "variables";

.body-messages {
    display: flex;

    .page {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        .page-fill {
            .content {
                margin-top: 0 !important;
                margin-bottom: 0 !important;

                .container {
                    padding-left: 0;
                    padding-right: 0;
                }

                .card {
                    border: 0;
                }

                .items {
                    padding-left: 0.75rem !important;
                    padding-right: 0.75rem !important;
                }
            }
        }
        .footer {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }
    }
}

.col-messages-conversations {
    border-right: 1px solid rgba(0, 40, 100, 0.12);
    overflow: hidden;
    @include media-breakpoint-down(sm) {
        display: none !important;
        border-right: 0;
        &.active {
            display: flex !important;
        }
    }
}

.col-messages-conversation {
    @include media-breakpoint-down(sm) {
        &.hide-messages {
            display: none;
        }
    }
}

.messages-conversations {
    height: 100%;
    overflow: hidden;
    position: relative;

    .wrapper-conversations {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        overflow-y: auto;
    }

    .conversations {
        .conversation {
            &.premium {
                background: #fff5ec;

                .avatar {
                    border: 2px solid #ff6600;
                    box-shadow: 0 0 3px #ff6600;
                }
            }

            .avatar {
                width: 48px;
                height: 48px;
                display: inline-block;
                flex: 0 0 auto;
            }

            .message {
                color: #444;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 80%;
                display: inherit;
            }

            &.active {
                background: #f4f8fb;

                .name {
                    color: #3c3c3c;
                }

                .message {
                    color: #696969;
                }
            }
        }
    }
}

.messages-conversation {
    position: relative;
    height: 100%;

    .conversation-header {
        border-bottom: 1px solid rgba(0, 40, 100, 0.12);
        height: 58px;
        background: #f4f8fb;

        .avatar {
            width: 48px;
            height: 48px;
        }

        .typing {
            font-style: italic;
            color: #5ab300;
            font-weight: 600;
            margin-right: 0.2rem;
            position: absolute;
            padding-left: 5px;
        }

        .verified {
            background: $blue;
            font-size: 10pt;
            width: 1rem;
            height: 1rem;
            min-width: 1rem;
            border-radius: 1rem;
            text-decoration: none;
            margin-right: 0.2rem;
            text-align: center;

            i.fe {
                color: $white;
            }
        }
    }

    .conversation-items {
        position: relative;
        overflow: hidden;
        flex: 1;

        .wrapper-items {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            overflow-y: auto;
            position: absolute;
        }

        .date {
            text-align: center;
            margin-top: 7px;
            margin-bottom: 7px;

            span {
                padding: 3px 7px;
                color: #777;
                border: 1px solid #ddd;
                font-size: 9pt;
                text-transform: uppercase;
                border-radius: 4px;
            }
        }

        .item {
            margin-bottom: 1.5rem;
            padding: 3px;
            //cursor: pointer;
            border-radius: 4px;
            @include clearfix;

            .avatar {
                margin-left: 0;
                margin-right: 0.5rem;
                width: 32px;
                height: 32px;
                display: inline-block;
                flex: 0 0 auto;
                background-size: 32px 32px;
            }

            .time {
                margin-left: 0.5rem;
                margin-right: 0;
            }

            .check {
                margin-top: 3px;
                color: #c6c6c6;

                &.pre {
                    color: #dedede;
                }
                &.checked {
                    color: #00a9ff;
                }
            }

            .spinner {
                margin-left: 0.5rem;
                margin-right: 0;
                color: #888;
            }

            .flex-row-reverse {
                .avatar {
                    margin-left: 0.5rem;
                    margin-right: 0;
                }

                .time {
                    margin-left: 0;
                    margin-right: 0.5rem;
                }

                .spinner {
                    margin-left: 0;
                    margin-right: 0.5rem;
                }
            }

            .text {
                max-width: 30rem;
                border-radius: 5px;

                &.sent {
                    background-color: #45aaf2;
                    color: #fff;
                }

                &.inbox {
                    background-color: #e9ecef;
                    color: #343a40;
                }

                .username {
                    font-size: 0.9em;
                    background-color: #e1e5e9;
                    color: #1e1e1e;
                    padding: 8px;
                    min-width: 100px;
                    border-radius: 5px 5px 0 0;
                }
                .btn {
                    padding: 0;
                    margin: -2px;
                    min-width: 20px;
                }
            }



            .images {
                .image {
                    position: relative;
                    img {
                        border-radius: 4px;
                        max-width: 100%;
                        height: auto;
                    }
                }
                .down {
                    position: absolute;
                    margin: 28% 0 0 36%;
                }
                .delete {
                    position: absolute;
                    margin: 3%;
                    padding: 0;
                    right: 0;
                }
            }

            &.sent {
                .item-body {
                    float: right;
                }


            }

            &.inbox {
                .item-body {
                    float: left;
                }

                .images .image img {
                    float: left;
                }
            }

            &.new {
                .text {
                    font-weight: bolder;
                }
            }

            &.selected {
                background: #ffeded;
            }
        }
    }

    .conversation-input {
         background: #f4f8fb;
        border-top: 1px solid rgba(0, 40, 100, 0.12);
        padding: .5rem;

        .btn-emoji-picker {
            background-color: #fff;
            color: #FF9800;
            .fa {
                font-size: 1rem;
            }
        }
        .btn-image-picker {
            background-color: #fff;
            color: #969eda;
            .fa {
                font-size: .8rem;
            }
        }

        .message-input {
            font-size: 1rem;
            height: 2.5rem;
        }

        .emoji-items {
            overflow-y: scroll;
            max-height: 200px;
            height: 200px;

            .emoji-item {
                display: inline-block;
                cursor: pointer;
                border-radius: 4px;
                padding: 4px;
                margin: 2px;
                width: 1.8rem;
                height: 1.8rem;
                text-align: center;
                user-select: none;

                &:hover {
                    background: $gray-300;
                }
            }
        }

        .input-group .btn:not(disabled) {
            z-index: 0;

            &:hover, &:focus {
                z-index: 1;
                border-color: $primary;
                outline: 0;
                box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
            }
        }
    }
}
