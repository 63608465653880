a {
    cursor: pointer;
}
.error-summary.alert {
  ul {
    margin-bottom: 0;
    padding-left: 15px;
  }
}

td.shrink {
  white-space: nowrap;
}
td.expand {
  width: 99%
}
.btn-group.full-width {
    display: flex;
    .btn {
        padding: 10px 0;
        flex: 1;
        color:#467fcf;
        background-color: #f4f8fb;
        border:1px solid #467fcf;
        &.active {
            color:#f4f8fb;
            background-color: $blue;
        }
    }
}

 .deleteButton {
    background-color: #FF5722;
    color: #fff !important;
    position: absolute;
    z-index: 9;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    right: -10px;
    top: -10px;
    cursor: pointer;
     &:hover{
         background-color: #b90100;

     }
}
