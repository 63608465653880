@import "mixins";

.profile-photos {
    .profile-photos-row {
        padding: 0 .25rem;
    }

    .profile-photos-gallery {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .profile-photo-item {
        position: relative;

        .photo-wrapper {
            padding-top: 100%;
            background: #ddebec;
            position: relative;
            margin-top: 1rem;
            border-radius: $border-radius;

            .loader {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -1.25rem;
                margin-left: -1.25rem;
                transform: scale(0.5);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                min-height: auto !important;
                border-radius: $border-radius;
            }

            .profile-photo-label {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                border-radius: $border-radius;
                background: rgba(0, 0, 0, .4);

                span {
                    border-radius: $border-radius;
                    color: $gray-200;
                    text-align: center;
                    padding: 3px;
                    font-size: 11pt;
                    line-height: 1rem;
                    text-shadow: 0 0 3px #223;
                    @include media-breakpoint-up(md) {
                        font-size: 11pt;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 9pt;
                        line-height: 0.9rem;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 12pt;
                        line-height: 1rem;
                    }
                }
            }
        }

        &.profile-photo-main {
            .photo-wrapper {
                margin-top: 0;

                .loader {
                    transform: scale(1);
                }
            }
        }
    }
}

.profile-info {
    .profile-info-block {
        padding: $profile-card-body-padding;
        border-bottom: 1px solid $profile-card-border-color;

        &:last-child {
            border-bottom: 0;
        }
    }
}

.profile-main-info {
    .first-line {
        margin-bottom: 0.5rem;

        h1, .age {
            display: inline-block;
            font-size: 17pt;
            margin-bottom: 0;
        }

        h1 {
            font-weight: bold;
        }
    }

    .second-line {
        .user-badge {
            width: $profile-badge-size;
            height: $profile-badge-size;
            border-radius: $profile-badge-size;
            font-size: 12pt;
            text-decoration: none;
        }

        .user-sex-badge {
            background: $gray-300;
            color: $gray-600;

            &.sex-male {
                background: $blue;
                color: $white;
            }

            &.sex-female {
                background: $pink;
                color: $white;
            }
        }

        .user-verified-badge {
            background: $green;

            i.fe {
                color: $white;
            }
        }

        .user-premium-badge {
            background: orange;
            font-size: 12pt;

            i.fe {
                color: $white;
            }
        }

        .user-location {
            line-height: $profile-badge-size;
            font-size: 13pt;
            color: $text-muted;
        }
    }




}

         .btn-blocked-toggle {

             &.btn-not-blocked {
                 background: $white;

                 i {
                     color: $gray-800;
                 }
             }

             &.btn-blocked {
                 background: $red;
                 color: $white;
             }
         }

.btn-favorite-toggle {


    &.btn-not-favorite {
        background: $gray-300;

        i {
            color: $gray-600;
        }
    }

    &.btn-favorite {
        background: $green;
        color: $white;
    }

}
.btn-invisible-toggle {


    &.btn-not-invisible {
        background: $white;
        color: $red;

        i {
        }
    }

    &.btn-invisible {
        background: $red;
        color: $white;
    }

}

.btn-smile-toggle {
    &.btn-not-smile {
        background: $gray-200;

        i {
            color: $gray-600;
        }
    }

    &.btn-smile {
        background: $yellow;
        color: $white;
    }
}
.profile-navigation {
    .navigation-item {
        margin-bottom: 0.5rem;

        &.active a, &:hover a {
            background: $white;
            border-color: $gray-200;
        }

        &.active a {
            color: $gray-700;
        }

        a {
            display: block;
            border: 1px solid transparent;
            border-radius: $border-radius;
            cursor: pointer;
            padding: 0.5rem 1rem;
            text-decoration: none;
            color: $gray-600;
        }
    }
}

.user-popularity {

}

.online-status {
    display: inline-block;
    margin-left: 0.5rem;
    width: .75rem;
    height: .75rem;
    border: 2px solid #fff;
    background: rgba(90, 90, 90, .7);
    border-radius: 50%;
}
