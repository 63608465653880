.timeline {
	position: relative;
	margin: 0 0 2rem;
	padding: 0;
	list-style: none;

	&:before {
		background-color: $gray-200;
		position: absolute;
		display: block;
		content: '';
		width: 1px;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 4px;
	}
}

.timeline-item {
	position: relative;
	display: flex;
	padding-left: 2rem;
	margin: .5rem 0;

	&:first-child:before,
	&:last-child:before {
		content: '';
		position: absolute;
		background: #fff;
		width: 1px;
		left: .25rem;
	}

	&:first-child {
		margin-top: 0;

		&:before {
			top: 0;
			height: .5rem;
		}
	}

	&:last-child {
		margin-bottom: 0;

		&:before {
			top: .5rem;
			bottom: 0;
		}
	}
}

.timeline-badge {
	position: absolute;
	display: block;
	width: px2rem(7px);
	height: px2rem(7px);
	left: 1px;
	top: .5rem;
	border-radius: 100%;
	border: 1px solid #fff;
	background: $gray-500;
}

.timeline-time {
	white-space: nowrap;
	margin-left: auto;
	color: $text-muted;
	font-size: $small-font-size;
}