@import "variables";

.group-verified-badge {
  background: $blue;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  font-size: 1.2rem;
  text-align: center;
  color: $white;
}

.group-header {
  @extend .card;
  .group-cover {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    min-height: 10rem;
    background-color: $blue;
    background-size: cover;
    @include media-breakpoint-up(md) {
      min-height: 12rem;
      max-height: 12rem;
    }
    @include media-breakpoint-up(lg) {
      min-height: 15rem;
      max-height: 15rem;
    }
  }
  .group-info {
    padding: 1rem;
    .group-photo {
      img,
      .no-photo {
        width: 3rem;
        height: 3rem;
        background: $gray-600;
        border-radius: 4px;
      }
    }
    h1 {
      font-size: 16pt;
      margin-bottom: 0;
    }
    .description {
      font-size: 12pt;
      color: $gray-500;
    }
    .group-verified-badge {
      margin-left: 0.5rem;
      display: inline-block;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 1.1rem;
      font-size: 1rem;
    }
  }
}

.group-users-grid-view {
  .profile-link:hover {
    text-decoration: none;
    .profile-name {
      text-decoration: underline;
    }
  }
}

.groups-list-view {
  .card {
    min-height: 12rem;
    max-height: 12rem;
    position: relative;
  }
  .card-aside-column {
    width: 50%;
    flex: 0 0 50%;
    text-decoration: none;
    i.fe, i.fa {
      text-decoration: none;
    }
    &.no-photo {
      background: $gray-400;
      i.fe, i.fa {
        font-size: 3rem;
        color: $gray-700;
      }
    }
  }
  .group-verified-badge {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
  }
}

