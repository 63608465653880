@import "variables";

.post {
  .post-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    .datetime {
      color: $gray-500;
      font-size: 0.8rem;
    }
  }
  .post-body {
    padding-top: 0;
  }
  .post-comments {
    padding: 0 1.5rem;
    border-top: 1px solid $border-color;
    margin-bottom: 1rem;
    .post-comments-reply {
      margin-top: 1rem;
    }
  }
  .post-attachments {
    margin-top: 0.5rem;
    .post-attachment-image {
      margin: 0.25rem;
      img {
        border-radius: $border-radius;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      };
    }
  }
  .post-footer {
    padding: 0.5rem 1.25rem;
    .btn-icon {
      background: none;
      border: 0;
      outline: none;
      font-size: 12pt;
      i.fa, i.fe {
        font-size: 13pt;
      }
      &.btn-like {
        color: $gray-500;
        &:hover {
          color: $red;
        }
      }
      &.vote-active {
        color: $red;
      }
      &.btn-comments {
        color: $gray-500;
        &:hover {
          color: $blue;
        }
      }
      &.btn-more {
        color: $gray-500;
        &:hover {
          color: $gray-700;
        }
      }
    }
  }
}

.post-new {
  .post-content-field {
    border-color: transparent;
    background: #f4f4f4;
  }
  .card-footer {
    display: none;
  }
  &.activated {
    .post-content-field {
      border-color: $blue;
      background: transparent;
    }
    .card-footer {
      display: block;
    }
  }
  .upload-kit .files {
    float: none;
    margin-bottom: 0;
  }
  .upload-kit .upload-kit-input {
    height: 38px;
    margin: 0;
    cursor: pointer;
    @extend #{'.btn', '.btn-secondary'};
    .btn-label {
      cursor: pointer;
    }
  }
}
