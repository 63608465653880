.empty-state {
  @extend .my-2, .d-flex, .justify-content-center, .flex-column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  .empty-icon {
    @extend .mb-4;
    i.fe, i.fa {
      color: $gray-300;
      font-size: 48pt;
    }
  }
  .empty-title {
    font-size: 16pt;
  }
  .empty-subtitle {
    @extend .text-muted;
    font-size: 12pt;
  }
  .empty-action {
    @extend .mt-2;
  }
}

.sidebar-menu {
  .sidebar-menu-line {
    height: 1px;
    background: $gray-200;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .list-group-item {
    .icon {
      margin-right: 0.75rem;
      margin-left: 0;
    }
    &:hover {
      background: rgba($primary, .03);
    }
    &.active:hover {
      background: rgba($primary, .06);
    }
    &.loading {
      position: relative;
    }
  }
}
html[dir=rtl] {
  .sidebar-menu {
    .list-group-item .icon {
      margin-left: 0;
      margin-right: 0.75rem;
    }
  }
}

.page-fill-wrapper {
  min-height: 500px;
}

.important-messages .alert {
  border-radius: 0;
}

.min-h-100 {
  min-height: 100%;
}

.hidden, .hide {
  display: none !important;
}
.no-padding {
  padding: 0;
}
.text-bold {
  font-weight: bold;
}
.text-bolder {
  font-weight: 600;
}
.text-small {
  font-size: 0.8rem;
}

.rounded {
  border-radius: $border-radius;
}

.tooltip {
  pointer-events: none;
}

@for $i from 1 through 10 {
  .max-lines-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-lines: $i;
    -webkit-line-clamp: $i;
  }
}
