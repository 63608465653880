.body-landing-page {
  .header {
    color: $white;
    padding-bottom: 16rem !important;
    background: #6604df;
    background:
            linear-gradient(135deg, hsla(262.64, 82.27%, 50.48%, 1) 0%, hsla(262.64, 82.27%, 50.48%, 0) 70%),
            linear-gradient(25deg, hsla(237.74, 23.62%, 33.34%, 1) 10%, hsla(237.74, 23.62%, 33.34%, 0) 80%),
            linear-gradient(315deg, hsla(225, 95%, 50%, 1) 15%, hsla(225, 95%, 50%, 0) 80%),
            linear-gradient(245deg, hsla(340, 100%, 55%, 1) 100%, hsla(340, 100%, 55%, 0) 70%);
    .btn-login {
      border-color: rgba(255, 255, 255, 0.7);
      color: rgba(255, 255, 255, 0.8);
      background: transparent;
      &:hover {
        border-color: rgba(255, 255, 255, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .content-landing {
    .card {
      border-radius: 10px;
      border: 0;
      box-shadow: 0 5px 25px 12px rgba(25, 25, 30, 0.1);
      margin-top: -16rem;
    }
    .landing-page-bg {
      background: #128bcf url('../images/landing-tree.jpg') center no-repeat;
      background-size: cover;
      border-radius: 10px 10px 0 0;
      position: relative;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      @include media-breakpoint-up(md) {
        min-height: 400px;
      }
      @include media-breakpoint-up(lg) {
        min-height: 650px;
        border-radius: 10px 0 0 10px;
      }
    }
    .landing-page-signup-head {
      padding: 2rem 0 2rem 0;
      h1 {
        color: #334;
        font-size: 22pt;
        margin-bottom: 10px;
        font-weight: bold;
        @include media-breakpoint-up(md) {
          font-size: 24pt;
        }
        @include media-breakpoint-up(lg) {
          font-size: 28pt;
        }
      }
      .subtitle {
        color: #778;
        font-size: 13pt;
        @include media-breakpoint-up(md) {
          font-size: 15pt;
        }
        @include media-breakpoint-up(lg) {
          font-size: 16pt;
        }
      }
    }
    .landing-page-signup {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }
    .landing-page-signup-form {
      .steps {
        position: relative;
        min-height: 200px;
        .step {
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          @include media-breakpoint-down(sm) {
            &.fadeOutUp {
              height: 0;
            }
          }
          @include media-breakpoint-up(lg) {
            position: absolute;
          }
        }
      }
      .select-group {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
        @include media-breakpoint-up(xl) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
      .select-group-title {
        padding: 0.3rem;
        text-align: center;
        color: #888;
        font-size: 13pt;
      }
      .select-group-sex .btn {
        font-size: 11pt;
        margin: 0.3rem;
        i {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        &.btn-male {
          border-color: $primary;
          color: $primary;
          i { color: $primary; }
          &:hover, &.active {
            background: $primary;
            color: $white;
            i { color: $white; }
          }
          &:focus {
            background: $primary;
            color: $white;
            box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
            i { color: $white; }
          }
        }
        &.btn-female {
          border-color: $pink;
          color: $pink;
          i { color: $pink; }
          &:hover, &.active {
            background: $pink;
            color: $white;
            i { color: $white; }
          }
          &:focus {
            background: $pink;
            color: $white;
            box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
            i { color: $white; }
          }
        }
        &.btn-secret {
          border-color: $gray-500;
          color: $gray-500;
          i { color: $gray-500; }
          &:hover, &.active {
            background: $gray-500;;
            color: $white;
            i { color: $white; }
          }
          &:focus {
            background: $gray-500;;
            color: $white;
            i { color: $white; }
          }
        }
      }
    }
    .landing-page-social-auth {
      text-align: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      .landing-or {
        color: #ababab;
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 0.9rem;
      }
      .social-auth-lp {
        .auth-clients li {
          float: none;
          display: inline-block;
          margin: 0 6px;
        }
      }
    }
  }
}
