.webcam-wrapper {
  position: relative;
  height: 100%;
  max-width: 400px;
  min-height: 300px;
  margin: auto;
  .webcam {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .webcam, .webcam video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
  }
  .webcam-camera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gray-500;
    @extend .d-flex, .justify-content-center, .align-items-center;
    i.fe {
      color: $gray-300;
      font-size: 48pt;
    }
  }
}

.verification-gesture {
  img {
    max-width: 120px;
    height: auto;
  }
}
